import config from '@/config.js';
export default {
  data() {
    return {
      config: config
    };
  },
  methods: {
    trackButtonClick() {
      this.$trackEvent({
        action: 'download',
        category: 'Download',
        label: 'DownloadFixed'
      });
    }
  }
};