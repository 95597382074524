import Header from './view/common/Header.vue';
import Footer from './view/common/Footer.vue';
import DownloadBar from './view/common/DownloadBar.vue';
import { ref, getCurrentInstance, reactive } from "vue";
import { useRect, useEventListener } from '@vant/use';
export default {
  setup() {
    const headbox = ref();
    const footbox = ref();
    useEventListener('scroll', () => {
      // const rect_head = useRect(headbox);
      // console.log(rect_head); // -> 元素的大小及其相对于视口的位置

      // const rect_foot = useRect(footbox);
      // console.log(rect_foot); // -> 元素的大小及其相对于视口的位置
    });
    return {
      headbox,
      footbox
    };
  },
  data() {
    return {
      showElement: false,
      scrollThresholdTop: 500,
      // 距离顶部多少距离触发显示元素
      scrollThresholdBottom: 100 // 距离底部多少距离触发显示元素
    };
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = Math.max(document.body.scrollHeight, document.documentElement.scrollHeight, document.body.offsetHeight, document.documentElement.offsetHeight, document.body.clientHeight, document.documentElement.clientHeight);
      const distanceToTop = scrollTop;
      const distanceToBottom = documentHeight - (scrollTop + windowHeight);

      // 滚动距离超过设定的阈值时显示元素，否则隐藏
      this.showElement = distanceToTop >= this.scrollThresholdTop && distanceToBottom >= this.scrollThresholdBottom;
    }
  },
  onLoad() {},
  onShow() {},
  components: {
    Header,
    Footer,
    DownloadBar
  }
};