import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "app_main"
};
const _hoisted_2 = {
  ref: "headbox"
};
const _hoisted_3 = {
  ref: "footbox"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Header = _resolveComponent("Header");
  const _component_router_view = _resolveComponent("router-view");
  const _component_Footer = _resolveComponent("Footer");
  const _component_DownloadBar = _resolveComponent("DownloadBar");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, null, 512), _createVNode(_component_Header), _createVNode(_component_router_view), _createVNode(_component_Footer), _withDirectives(_createVNode(_component_DownloadBar, null, null, 512), [[_vShow, $data.showElement]]), _createElementVNode("div", _hoisted_3, null, 512)]);
}